import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any;
  loading: boolean;
}
const useFarmlandServiceDetail = (): [ContentData, () => void, any] => {
  const [id, changeId] = useState<any>(null);
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    if (id) loadContent();
  }, [id]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(
      `/api/farmland/service/detail/${id}`
    );
    if (response.resultCode == 200)
      changeContent({
        loading: false,
        result: response.result || {},
      });
  }, [content, id]);

  return [content, loadContent, changeId];
};
export default useFarmlandServiceDetail;
