import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  Table,
  DatePicker,
  Layout,
  Select,
  Space,
  message,
  Form,
  PageHeader,
  Tooltip,
  Popconfirm,
  Typography,
  Tag,
  Descriptions,
  Checkbox,
  Skeleton,
} from "antd";
import {
  RollbackOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import BreadMe from "../lib/Breadme";
import usePlantType from "../../hooks/usePlantType";
import usePlant from "../../hooks/usePlant";
import NetworkApi from "../../network/networkApi";
import useFarmlandUsageDetail from "../../hooks/useFarmlandUsageDetail";
import UserContext from "../../providers/UserContext";
import moment, { Moment } from "moment";
import usePlantSort from "../../hooks/usePlantSort";
import useCustomerEquipmentList from "../../hooks/useCustomerEquipmentList";
import {
  GERMINATION_DEVELOPMENT_STAGE_LIST,
  GERMINATION_DEVELOPMENT_STAGE_REF,
  GERMINATION_MAIN_TYPE_LIST,
  GERMINATION_MAIN_TYPE_REF,
  GERMINATION_QUALITY_LIST,
  GERMINATION_QUALITY_REF,
} from "../../enums/germination.enum";
import { USER_ROLE } from "../../enums/user.enum";
import { useForm } from "antd/lib/form/Form";

interface QueryParams {
  id: any;
}
interface ContentData {
  result: any[];
  loading: boolean;
}
const FarmLandGrow = () => {
  const today = moment();
  const { id } = useParams<QueryParams>();
  const { user } = useContext(UserContext);
  const [form] = useForm();
  const canEdit = useMemo(() => {
    if (
      user.role?.cd == "ADMIN" ||
      user?.role?.cd == "VILLAGE_AGRONOMIST" ||
      user.role?.cd == "CUSTOMER"
    ) {
      return true;
    }
    return false;
  }, [user]);

  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();
  const [plantSort, , changePlantSortFilter] = usePlantSort();

  const [detail, , changeId] = useFarmlandUsageDetail();
  const [filter, changeFilter] = useState<any>({
    usage: id,
    year: today.format("YYYY"),
  });
  const [selectedDate, changeSelectedDate] = useState<Moment | null>(today);
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    form.setFieldValue("year", today);
  }, []);
  useEffect(() => {
    if (filter) loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/farmland/plant/list",
      filter
    );
    if (response.resultCode == 200)
      if (response.resultCode == 200)
        changeContent({
          loading: false,
          result: response.result || [],
        });
  }, [filter]);
  const [showPlantAdd, setPlantAdd] = useState(false);
  const [plantHarvest, changePlantHarvest] = useState({
    visible: false,
    id: 0,
  });
  const [cultivation, changeCultivation] = useState({
    visible: false,
    id: 0,
  });
  const [germination, changeGermination] = useState({
    visible: false,
    id: 0,
  });
  const [equipment, changeEquipment] = useState({
    visible: false,
    plant: undefined,
  });
  useEffect(() => {
    changeId(id);
  }, [id]);
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost(
      `/api/farmland/plant/delete/${data.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);
  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };
  const argoColumn = [
    {
      title: "№",
      width: 40,
      render: (t: any, ind: any, index: any) => index + 1,
    },
    {
      title: "Таримлын нэр",
      render: (t: any) => t.plant.name,
      ellipsis: true,
    },
    {
      title: "Таримлын сорт",
      dataIndex: "sort",
      render: (sort: any) => sort?.name,
    },
    {
      title: "Тариалсан хугацаа",
      render: (value: any) => `${value.startDate || ""}-${value.endDate || ""}`,
    },
    {
      title: "Тариалсан талбай",
      dataIndex: "area",
      render: (value: any) => `${value || 0} га`,
    },
    {
      title: "Хураасан хугацаа",
      render: (value: any) =>
        `${value.harvestStartDate || ""}-${value.harvestEndDate || ""}`,
    },
    {
      title: "Хураасан талбай",
      dataIndex: "totalArea",
      render: (value: any) => `${value || 0} га`,
    },
    {
      title: "Хураасан хэмжээ",
      render: (value: any) =>
        `${value.totalHarvest || 0}${value.plant.unit?.name || ""}`,
    },
    {
      title: "Соёололт",
      render: (row: any) => (
        <Button
          type="link"
          onClick={() => changeGermination({ visible: true, id: row.id })}
        >
          <Tag
            icon={<EyeOutlined />}
            style={{ fontWeight: "bolder", border: "none", borderRadius: 8 }}
          >
            Харах
          </Tag>
        </Button>
      ),
    },
    {
      title: "Ашигласан тоног төхөөрөмж",
      render: (row: any) => {
        return (
          <Button
            type="link"
            onClick={() => changeEquipment({ visible: true, plant: row })}
          >
            <Tag
              icon={<EyeOutlined />}
              style={{ fontWeight: "bolder", border: "none", borderRadius: 8 }}
            >
              Харах
            </Tag>
          </Button>
        );
      },
    },
    {
      title: "",
      width: 370,
      ellipsis: true,
      render: (data: any) => (
        <Space size={12}>
          <Button
            type="primary"
            onClick={() => changeCultivation({ visible: true, id: data.id })}
          >
            Тариалалт
          </Button>
          <Button
            type="primary"
            onClick={() => changePlantHarvest({ visible: true, id: data.id })}
          >
            Ургац хураалт
          </Button>
          {detail?.result.status === "active" && (
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => deleteItem(data)}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <Button
                onClick={() => {}}
                type={"primary"}
                size={"small"}
                danger
                ghost
                icon={<DeleteOutlined />}
              >
                Устгах
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadMe title={["Тариалангийн талбай", "Тариалангийн талбайн дугаар"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={[16, 16]} justify={"space-between"}>
          <Col span={28}>
            <Space size={40}>
              <Form
                form={form}
                layout="inline"
                onFinish={(p) => {
                  changeFilter({
                    ...filter,
                    ...p,
                    usage: id,
                    year: selectedDate?.format("YYYY"),
                  });
                }}
              >
                <Form.Item name="year">
                  <DatePicker
                    picker="year"
                    onChange={(value) => {
                      changeSelectedDate(value);
                    }}
                  />
                </Form.Item>
                <Form.Item name="plantTypeId">
                  <Select
                    placeholder="Таримлын төрөл"
                    onChange={(v) => {
                      changePlantFilter({ type: v });
                      form.setFieldsValue({
                        plantId: undefined,
                        sortId: undefined,
                      });
                    }}
                    allowClear
                  >
                    {plantType.result.map((p: any) => (
                      <Select.Option value={p.id}>{p.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="plantId">
                  <Select
                    placeholder="Таримал"
                    onChange={(v) => {
                      changePlantSortFilter({ plantId: v });
                      form.setFieldsValue({ sortId: undefined });
                    }}
                    allowClear
                  >
                    {plant.result.map((p: any) => (
                      <Select.Option value={p.id}>{p.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="sortId">
                  <Select placeholder="Таримлын сорт" allowClear>
                    {plantSort.result.map((p: any) => (
                      <Select.Option value={p.id}>{p.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button htmlType="submit" type={"primary"}>
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
        </Row>
        <PageHeader
          subTitle={`${detail.result?.area}га`}
          title={`НТДугаар: ${detail.result?.farmland?.posNo}`}
          extra={[
            <Button
              icon={<RollbackOutlined />}
              type={"primary"}
              onClick={() => window.history.back()}
            >
              Талбайн түүхрүү буцах
            </Button>,
            canEdit && detail?.result.status === "active" ? (
              <Button
                onClick={() => setPlantAdd(true)}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Таримал нэмэх
              </Button>
            ) : null,
          ]}
          style={{ marginBottom: 15 }}
        />
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              style={{ width: "100%" }}
              columns={argoColumn}
              dataSource={content.result}
              onChange={handleParam}
            />
          </Col>
        </Row>
      </Layout.Content>
      <PlantAdd
        usage={id}
        visible={showPlantAdd}
        onHide={() => {
          setPlantAdd(false);
          loadContent();
        }}
        onSuccess={() => {
          setPlantAdd(false);
          loadContent();
        }}
        id={1}
      />
      {cultivation?.visible && (
        <PlantCultivation
          visible={cultivation.visible}
          id={cultivation.id}
          onSuccess={() => {
            changeCultivation({ visible: false, id: 0 });
            loadContent();
          }}
          hide={() => {
            changeCultivation({ visible: false, id: 0 });
            loadContent();
          }}
          status={detail?.result.status}
        />
      )}
      {germination?.visible && (
        <PlantGermination
          visible={germination.visible}
          id={germination.id}
          onSuccess={() => {
            changeGermination({ visible: false, id: 0 });
            loadContent();
          }}
          hide={() => {
            changeGermination({ visible: false, id: 0 });
            loadContent();
          }}
          status={detail?.result.status}
        />
      )}
      {plantHarvest?.visible && (
        <PlantHarvest
          visible={plantHarvest.visible}
          id={plantHarvest.id}
          onSuccess={() => {
            changePlantHarvest({ visible: false, id: 0 });
            loadContent();
          }}
          hide={() => {
            changePlantHarvest({ visible: false, id: 0 });
            loadContent();
          }}
          status={detail?.result.status}
        />
      )}
      {equipment?.visible && (
        <PlantEquipmentList
          visible={equipment.visible}
          plant={equipment.plant}
          onHide={() => {
            changeEquipment({ visible: false, plant: undefined });
            loadContent();
          }}
        />
      )}
    </>
  );
};

interface PlantAddEditProps {
  visible: boolean;
  onHide: any;
  onSuccess: any;
  usage: any;
  id?: any;
}
const PlantAdd = ({
  visible,
  onHide,
  onSuccess,
  usage,
  id,
}: PlantAddEditProps) => {
  const [plantForm] = Form.useForm();
  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();
  const [plantSort, , changePlantSortFilter] = usePlantSort();
  const onFinish = useCallback(
    async (data) => {
      if (data.plantId) {
        let response = await NetworkApi.requestPost(
          "/api/farmland/plant/update",
          { usageId: usage, plantId: data.plantId, sortId: data.sortId }
        );
        if (response.resultCode == 200) {
          onSuccess();
        } else {
          message.error(response.message || "Таримал сонгогдоогүй байна");
        }
      } else {
        message.error("Таримал сонгогдоогүй байна");
      }
    },
    [usage, onSuccess]
  );
  return (
    <Modal
      visible={visible}
      onCancel={onHide}
      onOk={() => plantForm.submit()}
      title={"Таримал нэмэх"}
    >
      <Form
        form={plantForm}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item name="plantTypeId" label="Таримлын төрөл">
          <Select
            placeholder="Таримлын төрөл сонгоно уу"
            onChange={(v) => {
              changePlantFilter({ type: v });
              plantForm.setFieldsValue({
                plantId: undefined,
                sortId: undefined,
              });
            }}
          >
            {plantType.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="plantId" label="Таримлын нэр">
          <Select
            placeholder="Таримал сонгоно уу"
            onChange={(v) => {
              changePlantSortFilter({ plantId: v });
              plantForm.setFieldsValue({ sortId: undefined });
            }}
          >
            {plant.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sortId" label="Таримлын сорт">
          <Select placeholder="Таримлын сорт сонгоно уу">
            {plantSort.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface PlantEquipmentListProps {
  visible: boolean;
  onHide: () => void;
  plant: any;
}

const PlantEquipmentList = ({
  visible,
  onHide,
  plant,
}: PlantEquipmentListProps) => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      `/api/farmland/plant/equipment/${plant?.id}`,
      {
        ...content.filter,
        page: content.page || 1,
      }
    );
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.equipments || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  const columns = [
    {
      title: "#",
      render: (item: any) => item.id,
    },
    {
      title: "Тоног төхөөрөмжийн нэр",
      dataIndex: "equipment",
      render: (item: any) =>
        `${item?.type.name} ${item?.subType.name} ${item?.mark}`,
    },
    {
      title: "Ашигласан огноо",
      dataIndex: "usedDate",
    },
    {
      title: "Таримал",
      dataIndex: "id",
      render: () => `${plant?.plant.name}`,
    },
  ];
  return (
    <Modal
      visible={visible}
      title={`Ашигласан тоног төхөөрөмж`}
      onCancel={onHide}
      footer={null}
    >
      <Table
        loading={content.loading}
        columns={columns}
        dataSource={content.result}
        scroll={{ x: true }}
      />
    </Modal>
  );
};

interface ModalProps {
  visible: boolean;
  hide: () => void;
  onSuccess: () => void;
  id?: number;
  status: string;
}
interface CultivationState {
  loading: boolean;
  cultivation: any[];
  plant: any;
}
const PlantCultivation = ({
  visible,
  hide,
  onSuccess,
  id,
  status,
}: ModalProps) => {
  const { user } = useContext(UserContext);
  const [editing, setEditing] = useState<any>(null);
  const [showEquipmentList, setShowEquipmentList] = useState<{
    visible: boolean;
    id?: string;
  }>({
    visible: false,
  });
  const [customerEquipmentList, loadCustomerEquipmentList] =
    useCustomerEquipmentList(1);
  const canEdit = useMemo(() => {
    if (
      user.role?.cd == "ADMIN" ||
      user?.role?.cd == "VILLAGE_AGRONOMIST" ||
      user.role?.cd == "CUSTOMER"
    ) {
      return true;
    }
    return false;
  }, [user]);
  const [cultivationForm] = Form.useForm();
  const [content, changeContent] = useState<CultivationState>({
    loading: true,
    cultivation: [],
    plant: {},
  });
  useEffect(() => {
    if (visible && id) {
      loadContent();
      loadCustomerEquipmentList();
      setEditing(null);
    }
  }, [visible, id]);
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      `/api/farmland/plant/cultivation/detail/${id}`
    );
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        plant: response.result.plant || {},
        cultivation: response.result.cultivationList || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [id]);
  const deleteCultivation = useCallback(
    async (i: any) => {
      let response = await NetworkApi.requestGet(
        `/api/farmland/plant/cultivation/delete/${i}`
      );
      if (response.resultCode == 200) {
        message.success("Амжилттай устгагдлаа");
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [id]
  );
  const columns = [
    {
      title: "Тариалсан огноо",
      dataIndex: "startDate",
    },
    {
      title: "Тариалсан талбайн хэмжээ",
      dataIndex: "area",
    },
    {
      title: "Ашигласан тоног төхөөрөмж",
      dataIndex: "id",
      render: (value: string) => (
        <Button
          type="link"
          onClick={() => setShowEquipmentList({ visible: true, id: value })}
        >
          <Tag
            icon={<EyeOutlined />}
            style={{ fontWeight: "bolder", border: "none", borderRadius: 8 }}
          >
            Харах
          </Tag>
        </Button>
      ),
    },
    {
      title: "Үйлдэл",
      render: (value: any) => (
        <Space size={10}>
          {canEdit && status === "active" && !content.plant?.finishDate ? (
            <>
              <Tooltip title="Засах">
                <Button
                  onClick={() => {
                    setEditing(value);
                    cultivationForm.setFieldsValue({
                      ...value,
                      startDate: moment(value.startDate),
                      equipmentIds: value.equipments.map(
                        (equipment: any) => equipment.equipmentId
                      ),
                    });
                  }}
                  type={"primary"}
                  size={"small"}
                  icon={<EditOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title="Устгах">
                <Popconfirm
                  placement="topLeft"
                  title={"Та устгахдаа итгэлтэй байна уу?"}
                  onConfirm={() => {
                    deleteCultivation(value.id);
                  }}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    type={"primary"}
                    danger
                    size={"small"}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Popconfirm>
              </Tooltip>
            </>
          ) : null}
        </Space>
      ),
    },
  ];
  const [loading, changeLoading] = useState(false);
  const submitContent = useCallback(
    async (data: any) => {
      changeLoading(true);
      if (data.startDate) {
        data.startDate = data.startDate.format("YYYY-MM-DD");
      }
      data.farmlandPlantId = content.plant.id;
      if (editing != null) {
        data.id = editing.id;
      }
      let response = await NetworkApi.requestPost(
        "/api/farmland/plant/cultivation/add",
        data
      );
      if (response.resultCode == 200) {
        loadContent();
        cultivationForm.resetFields();
        message.success("Амжилттай нэмэгдлээ");
        setEditing(null);
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
      changeLoading(false);
    },
    [cultivationForm, content, editing]
  );
  return (
    <Modal
      width={800}
      visible={visible}
      onCancel={hide}
      title={`${content.plant.plant?.name}-Тариалалт`}
      footer={null}
    >
      <Skeleton loading={content.loading}>
        {canEdit && status === "active" && !content.plant?.finishDate ? (
          <Row>
            <Col span={24}>
              <Form
                layout="horizontal"
                form={cultivationForm}
                onFinish={submitContent}
              >
                <Space size={16}>
                  <Form.Item name="startDate" label="Тариалсан огноо">
                    <DatePicker style={{ minWidth: 120 }} />
                  </Form.Item>
                  <Form.Item name="area">
                    <Input
                      addonAfter={"га"}
                      style={{ width: 200 }}
                      placeholder="Тариалсан талбай"
                    />
                  </Form.Item>
                </Space>
                <Row gutter={16}>
                  <Col span={16}>
                    <Form.Item
                      name="equipmentIds"
                      label="Ашигласан тоног төхөөрөмж"
                    >
                      <Select
                        style={{ minWidth: 150 }}
                        allowClear
                        placeholder="Тоног төхөөрөмж"
                        mode="multiple"
                      >
                        {customerEquipmentList.result.map((equipment) => {
                          return (
                            <Select.Option
                              key={equipment.id}
                              value={equipment.id}
                            >
                              {`${equipment?.type.name} ${equipment?.subType.name} ${equipment?.mark}`}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <Space size={16}>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                        >
                          {editing == null ? "Нэмэх" : "Засах"}
                        </Button>
                        {editing == null ? null : (
                          <Button
                            loading={loading}
                            onClick={() => {
                              setEditing(null);
                              cultivationForm.resetFields();
                            }}
                          >
                            Болих
                          </Button>
                        )}
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : null}
        <Table
          pagination={false}
          columns={columns}
          dataSource={content.cultivation}
          scroll={{ x: true }}
        />
        {showEquipmentList.visible && showEquipmentList.id && (
          <CultivationEquipmentList
            visible={showEquipmentList.visible}
            id={showEquipmentList.id}
            plant={content.plant?.plant}
            onHide={() =>
              setShowEquipmentList({ visible: false, id: undefined })
            }
          />
        )}
      </Skeleton>
    </Modal>
  );
};

interface CultivationEquipmentListProps {
  visible: boolean;
  onHide: () => void;
  id: string;
  plant: any;
}

const CultivationEquipmentList = ({
  visible,
  onHide,
  id,
  plant,
}: CultivationEquipmentListProps) => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      `/api/farmland/plant/equipment/list/${id}`,
      {
        ...content.filter,
        page: content.page || 1,
        type: "cultivation",
      }
    );
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.equipments || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  const columns = [
    {
      title: "#",
      render: (item: any) => item.id,
    },
    {
      title: "Тоног төхөөрөмжийн нэр",
      dataIndex: "equipment",
      render: (item: any) =>
        `${item?.type.name} ${item?.subType.name} ${item?.mark}`,
    },
    {
      title: "Ашигласан огноо",
      dataIndex: "usedDate",
    },
    {
      title: "Таримал",
      dataIndex: "id",
      render: () => `${plant?.name}`,
    },
  ];
  return (
    <Modal
      visible={visible}
      title={`Ашигласан тоног төхөөрөмж`}
      onCancel={onHide}
      footer={null}
    >
      <Table
        loading={content.loading}
        columns={columns}
        dataSource={content.result}
      />
    </Modal>
  );
};

interface HarvestState {
  loading: boolean;
  plant: any;
  harvest: any[];
  totalHarvest: number;
  totalCultivation: number;
}
const PlantHarvest = ({ visible, hide, onSuccess, id, status }: ModalProps) => {
  const [harvestForm] = Form.useForm();
  const [content, changeContent] = useState<HarvestState>({
    loading: true,
    harvest: [],
    plant: {},
    totalHarvest: 0,
    totalCultivation: 0,
  });
  const [showEquipmentList, setShowEquipmentList] = useState<{
    visible: boolean;
    id?: string;
  }>({
    visible: false,
  });
  const [customerEquipmentList, loadCustomerEquipmentList] =
    useCustomerEquipmentList(1);
  const [editing, setEditing] = useState<any>(null);
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (
      user.role?.cd == "ADMIN" ||
      user?.role?.cd == "VILLAGE_AGRONOMIST" ||
      user.role?.cd == "CUSTOMER"
    ) {
      return true;
    }
    return false;
  }, [user]);
  useEffect(() => {
    if (visible && id) {
      loadContent();
      loadCustomerEquipmentList();
      setEditing(null);
    }
  }, [visible, id]);
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      `/api/farmland/plant/harvest/detail/${id}`
    );
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        totalHarvest: response.result.totalHarvest ?? 0,
        totalCultivation: response.result.totalCultivation ?? 0,
        plant: response.result.plant || {},
        harvest: response.result.harvestList || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [id]);
  const deleteHarvest = useCallback(
    async (i: any) => {
      let response = await NetworkApi.requestGet(
        `/api/farmland/plant/harvest/delete/${i}`
      );
      if (response.resultCode == 200) {
        message.success("Амжилттай устгагдлаа");
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [id]
  );
  const columns = [
    {
      title: "Ургац хураасан огноо",
      dataIndex: "startDate",
    },
    {
      title: "Хураасан талбайн хэмжээ",
      dataIndex: "area",
    },
    {
      title: "Хураасан хэмжээ",
      dataIndex: "harvest",
    },
    {
      title: "Ашигласан тоног төхөөрөмж",
      dataIndex: "id",
      render: (value: string) => (
        <Button
          type="link"
          onClick={() => setShowEquipmentList({ visible: true, id: value })}
        >
          <Tag
            icon={<EyeOutlined />}
            style={{ fontWeight: "bolder", border: "none", borderRadius: 8 }}
          >
            Харах
          </Tag>
        </Button>
      ),
    },
    {
      title: "Үйлдэл",
      render: (value: any) => (
        <Space size={10}>
          {canEdit && status === "active" && !content.plant.finishDate ? (
            <>
              <Tooltip title="Засах">
                <Button
                  onClick={() => {
                    setEditing(value);
                    harvestForm.setFieldsValue({
                      ...value,
                      startDate: moment(value.startDate),
                      equipmentIds: value.equipments.map(
                        (equipment: any) => equipment.equipmentId
                      ),
                    });
                  }}
                  type={"primary"}
                  size={"small"}
                  icon={<EditOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title="Устгах">
                <Popconfirm
                  placement="topLeft"
                  title={"Та устгахдаа итгэлтэй байна уу?"}
                  onConfirm={() => {
                    deleteHarvest(value.id);
                  }}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    type={"primary"}
                    danger
                    size={"small"}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Popconfirm>
              </Tooltip>
            </>
          ) : null}
        </Space>
      ),
    },
  ];
  const [loading, changeLoading] = useState(false);
  const submitContent = useCallback(
    async (data: any) => {
      changeLoading(true);
      if (data.startDate) {
        data.startDate = data.startDate.format("YYYY-MM-DD");
      }
      data.farmlandPlantId = content.plant.id;
      if (editing != null) {
        data.id = editing.id;
      }
      let response = await NetworkApi.requestPost(
        "/api/farmland/plant/harvest/add",
        data
      );
      if (response.resultCode == 200) {
        loadContent();
        harvestForm.resetFields();
        message.success("Амжилттай нэмэгдлээ");
        setEditing(null);
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
      changeLoading(false);
    },
    [harvestForm, content, editing]
  );

  const [endHarvestForm] = Form.useForm();
  const [endHarvestChecked, setEndHarvestChecked] = useState(false);
  const [lostHarvestChecked, setLostHarvestChecked] = useState(false);
  const [loadingEndHarvest, setLoadingEndHarvest] = useState(false);
  const [loadingCancelEndHarvest, setLoadingCancelEndHarvest] = useState(false);
  const submitEndHarvest = useCallback(
    async (data: any) => {
      setLoadingEndHarvest(true);
      let response = await NetworkApi.requestPost(
        `/api/farmland/plant/end/${id}`,
        {
          finishDate: moment().format("YYYY-MM-DD"),
          lossFlg: lostHarvestChecked,
          lossArea: data.lossArea ?? "0",
          lossReason: data.lossReason ?? "1",
        }
      );
      if (response.resultCode === 200) {
        loadContent();
        message.success("Амжилттай нэмэгдлээ");
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
      setLoadingEndHarvest(false);
    },
    [lostHarvestChecked]
  );
  const cancelEndHarvest = useCallback(async () => {
    setLoadingCancelEndHarvest(true);
    let response = await NetworkApi.requestPost(
      `/api/farmland/plant/end/undo/${id}`
    );
    if (response.resultCode === 200) {
      loadContent();
      message.success("Амжилттай цуцлагдлаа");
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    setLoadingCancelEndHarvest(false);
  }, [lostHarvestChecked]);
  return (
    <Modal
      width={1100}
      visible={visible}
      onCancel={hide}
      title={`${content.plant.plant?.name}-Ургац хураалт`}
      footer={null}
    >
      <Skeleton loading={content.loading}>
        {canEdit && status === "active" && !content.plant.finishDate ? (
          <Row>
            <Form
              layout="horizontal"
              form={harvestForm}
              onFinish={submitContent}
            >
              <Space size={16}>
                <Form.Item name="startDate" label="Хураасан огноо">
                  <DatePicker style={{ minWidth: 120 }} />
                </Form.Item>
                <Form.Item name="area">
                  <Input
                    addonAfter={"га"}
                    style={{ width: 120 }}
                    placeholder="Талбай"
                  />
                </Form.Item>
                <Form.Item name="harvest">
                  <Input
                    addonAfter="кг"
                    style={{ width: 200 }}
                    placeholder="Хураасан хэмжээ"
                  />
                </Form.Item>
              </Space>
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    name="equipmentIds"
                    label="Ашигласан тоног төхөөрөмж"
                  >
                    <Select
                      style={{ minWidth: 150 }}
                      allowClear
                      placeholder="Тоног төхөөрөмж"
                      mode="multiple"
                    >
                      {customerEquipmentList.result.map((equipment) => {
                        return (
                          <Select.Option
                            key={equipment.id}
                            value={equipment.id}
                          >
                            {`${equipment?.type.name} ${equipment?.subType.name} ${equipment?.mark}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Space size={16}>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                      >
                        {editing == null ? "Нэмэх" : "Засах"}
                      </Button>
                      {editing == null ? null : (
                        <Button
                          loading={loading}
                          onClick={() => {
                            setEditing(null);
                            harvestForm.resetFields();
                          }}
                        >
                          Болих
                        </Button>
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>
        ) : null}
        <Table
          pagination={false}
          columns={columns}
          dataSource={content.harvest}
          scroll={{ x: true }}
        />
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Descriptions>
            <Descriptions.Item label="Тариалсан нийт талбай">
              {content.totalCultivation}
            </Descriptions.Item>
            <Descriptions.Item label="Ургац хураасан нийт талбай">
              {content.totalHarvest}
            </Descriptions.Item>
            <Descriptions.Item label="Ургац хураалт дууссан эсэх">
              {content.plant.finishDate ? (
                user?.role.cd == "ADMIN" ? (
                  <>
                    <Checkbox checked>Дууссан</Checkbox>
                    <Popconfirm
                      title="Ургац хураалтыг дуусгасныг цуцлах уу?"
                      onConfirm={() => cancelEndHarvest()}
                      okText="Тийм"
                      cancelText="Үгүй"
                    >
                      <Button loading={loadingCancelEndHarvest}>
                        <CloseCircleFilled />
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <Checkbox checked>Дууссан</Checkbox>
                )
              ) : (
                <Checkbox
                  // checked={endHarvestChecked}
                  onChange={(e) => setEndHarvestChecked(e.target.checked)}
                >
                  Дууссан
                </Checkbox>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        {endHarvestChecked &&
          status === "active" &&
          !content.plant.finishDate && (
            <Row justify="end" style={{ marginTop: 15, marginBottom: 15 }}>
              <Col span={12}>
                <Form
                  form={endHarvestForm}
                  layout="horizontal"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={submitEndHarvest}
                >
                  <Form.Item label="Ургац алдсан эсэх" name="lossFlg">
                    <Checkbox
                      onChange={(e) => setLostHarvestChecked(e.target.checked)}
                    ></Checkbox>
                  </Form.Item>
                  <Form.Item
                    label="Ургац алдсан га"
                    name="lossArea"
                    rules={[
                      {
                        required: lostHarvestChecked,
                        message: "Ургац алдсан га оруулна уу",
                      },
                    ]}
                  >
                    <Input disabled={!lostHarvestChecked} />
                  </Form.Item>
                  <Form.Item
                    label="Ургац алдсан шалтгаан"
                    name="lossReason"
                    rules={[
                      {
                        required: lostHarvestChecked,
                        message: "Ургац алдсан шалтгаан оруулна уу",
                      },
                    ]}
                  >
                    <Select disabled={!lostHarvestChecked}>
                      <Select.Option value="1">Ганд нэрвэгдсэн</Select.Option>
                      <Select.Option value="2">Цасанд өртсөн</Select.Option>
                      <Select.Option value="3">Бусад</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Popconfirm
                      title="Ургац хураалтыг дуусгаснаар мэдээллийг засах боломжгүй болохыг анхаарна уу"
                      onConfirm={() => endHarvestForm.submit()}
                      okText="Тийм"
                      cancelText="Үгүй"
                    >
                      <Button loading={loadingEndHarvest} type="primary">
                        Хадгалах
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
        {showEquipmentList.visible && showEquipmentList.id && (
          <HarvestEquipmentList
            visible={showEquipmentList.visible}
            id={showEquipmentList.id}
            plant={content.plant?.plant}
            onHide={() =>
              setShowEquipmentList({ visible: false, id: undefined })
            }
          />
        )}
      </Skeleton>
    </Modal>
  );
};

interface HarvestEquipmentListProps {
  visible: boolean;
  onHide: () => void;
  id: string;
  plant: any;
}

const HarvestEquipmentList = ({
  visible,
  onHide,
  id,
  plant,
}: HarvestEquipmentListProps) => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      `/api/farmland/plant/equipment/list/${id}`,
      {
        ...content.filter,
        page: content.page || 1,
        type: "harvest",
      }
    );
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.equipments || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  const columns = [
    {
      title: "Төрөл",
      render: (item: any) => item.id,
    },
    {
      title: "Тоног төхөөрөмжийн нэр",
      dataIndex: "equipment",
      render: (item: any) =>
        `${item?.type.name} ${item?.subType.name} ${item?.mark}`,
    },
    {
      title: "Ашигласан онгоо",
      dataIndex: "usedDate",
    },
    {
      title: "Таримал",
      dataIndex: "id",
      render: () => `${plant?.name}`,
    },
  ];
  return (
    <Modal
      visible={visible}
      title={`Ашигласан тоног төхөөрөмж`}
      onCancel={onHide}
      footer={null}
    >
      <Table
        loading={content.loading}
        columns={columns}
        dataSource={content.result}
        scroll={{ x: true }}
      />
    </Modal>
  );
};

interface GerminationState {
  loading: boolean;
  germination: any[];
  plant: any;
}
const PlantGermination = ({
  visible,
  hide,
  onSuccess,
  id,
  status,
}: ModalProps) => {
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (
      user.role?.cd == "ADMIN" ||
      user?.role?.cd == "VILLAGE_AGRONOMIST" ||
      user.role?.cd == "CUSTOMER"
    ) {
      return true;
    }
    return false;
  }, [user]);
  const [edit, changeEdit] = useState<{
    visible: boolean;
    edit?: any;
  }>({
    visible: false,
  });
  const setEdit = (visible?: boolean, edit?: any) => {
    changeEdit({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };
  const [content, changeContent] = useState<GerminationState>({
    loading: true,
    germination: [],
    plant: {},
  });
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(`/api/germination/list/${id}`);
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        plant: response.result.plant || {},
        germination: response.result.germinations || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      loadContent();
    }
  }, [edit, id]);
  const deleteGermination = useCallback(
    async (germinationId: any) => {
      let response = await NetworkApi.requestPost(
        `/api/germination/delete/${germinationId}`
      );
      if (response.resultCode == 200) {
        message.success("Амжилттай устгагдлаа");
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [id]
  );
  const approveGermination = useCallback(
    async (germinationId: any) => {
      let response = await NetworkApi.requestPost(
        `/api/germination/approve/${germinationId}`
      );
      if (response.resultCode == 200) {
        message.success("Амжилттай баталгаажлаа");
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [id]
  );
  const columns = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Үндсэн боловсруулалтын төрөл",
      dataIndex: "mainType",
      render: (value: number) => `${GERMINATION_MAIN_TYPE_REF[value]}`,
    },
    {
      title: "Үрийн норм",
      children: [
        {
          title: "кг-аа",
          dataIndex: "byKg",
        },
        {
          title: "сая/ширхэгээр",
          dataIndex: "byUnit",
        },
      ],
    },
    {
      title: "Үр суултын гүн /см-ээр",
      dataIndex: "depth",
    },
    {
      title: "1м2-т цухуйсан ургамлын тоо ш",
      dataIndex: "plantAmount",
    },
    {
      title: "Хөгжлийн үе шат",
      dataIndex: "devStage",
      render: (value: number) => `${GERMINATION_DEVELOPMENT_STAGE_REF[value]}`,
    },
    {
      title: "Хогтолтын зэрэг баллаар",
      dataIndex: "wasteDegree",
    },
    {
      title: "Чанарын үнэлгээ",
      dataIndex: "quality",
      render: (value: number) => `${GERMINATION_QUALITY_REF[value]}`,
    },
    {
      title: "Баталгаажуулсан мэргэжилтэн",
      render: (item: any) => {
        if (
          user?.role?.cd == USER_ROLE.VILLAGE_AGRONOMIST &&
          item.status === "Pending"
        ) {
          return (
            <Popconfirm
              placement="topLeft"
              title={
                <Typography.Paragraph
                  style={{ wordBreak: "break-word", maxWidth: 250 }}
                >
                  Соёололтын мэдээг баталгаажуулснаар мэдээллийг засах боломжгүй
                  болохыг анхаарна уу
                </Typography.Paragraph>
              }
              onConfirm={() => {
                approveGermination(item?.id);
              }}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <Button type={"primary"} ghost size={"small"}>
                Баталгаажуулах
              </Button>
            </Popconfirm>
          );
        }
        return item?.confirmedUser
          ? `${item?.confirmedUser?.lastName.substring(0, 1)}.${
              item?.confirmedUser?.firstName
            }`
          : "";
      },
    },
    {
      title: "Үйлдэл",
      render: (value: any) => (
        <Space size={10}>
          {canEdit && status === "active" && !content.plant.finishDate ? (
            <>
              <Tooltip title="Засах">
                <Button
                  onClick={() => {
                    setEdit(true, value);
                  }}
                  type={"primary"}
                  size={"small"}
                  icon={<EditOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title="Устгах">
                <Popconfirm
                  placement="topLeft"
                  title={"Та устгахдаа итгэлтэй байна уу?"}
                  onConfirm={() => {
                    deleteGermination(value.id);
                  }}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    type={"primary"}
                    danger
                    size={"small"}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Popconfirm>
              </Tooltip>
            </>
          ) : null}
        </Space>
      ),
    },
  ];
  return (
    <Modal
      width={1200}
      visible={visible}
      onCancel={hide}
      title={`Тариалалтын чанар соёололтын дүн оруулах`}
      footer={null}
    >
      <Skeleton loading={content.loading}>
        <Row justify="end">
          <Col flex="none" style={{ marginTop: 15, marginBottom: 15 }}>
            <Row justify={"end"}>
              {canEdit && status === "active" && !content.plant.finishDate && (
                <Button
                  onClick={() => setEdit(true)}
                  type={"primary"}
                  icon={<PlusSquareOutlined />}
                >
                  Нэмэх
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        <Table
          pagination={false}
          columns={columns}
          dataSource={content.germination}
          scroll={{ x: true }}
        />
        <GerminationAddEdit
          farmlandPlantId={id}
          visible={edit.visible}
          edit={edit.edit}
          onHide={() => setEdit(false)}
          onSuccess={() => {
            setEdit(false);
            loadContent();
          }}
        />
      </Skeleton>
    </Modal>
  );
};

interface GerminationAddEditProps {
  farmlandPlantId: any;
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const GerminationAddEdit = ({
  edit,
  farmlandPlantId,
  visible,
  onHide,
  onSuccess,
}: GerminationAddEditProps) => {
  const [germinationForm] = Form.useForm();
  useEffect(() => {
    if (visible) {
      if (edit) {
        germinationForm.setFieldsValue({
          ...edit,
          usageStartDate: moment(edit.usageStartDate),
          manufacturedDate: moment(edit.manufacturedDate),
        });
      } else {
        germinationForm.resetFields();
      }
    }
  }, [edit, visible]);
  const saveContent = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      const response = await NetworkApi.requestPost("/api/germination/update", {
        ...data,
        farmlandPlantId,
      });
      if (response.resultCode === 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );

  return (
    <Modal
      visible={visible}
      title={edit ? "Соёололт засах" : "Соёололт нэмэх"}
      onCancel={onHide}
      footer={null}
      width={1214}
    >
      <Form
        form={germinationForm}
        layout="vertical"
        className="form-with-label-wrap"
        onFinish={saveContent}
      >
        <Row gutter={24}>
          <Col sm={24} md={12}>
            <Form.Item
              name="mainType"
              label="Үндсэн боловсруулалтын төрөл : "
              rules={[
                {
                  required: true,
                  message: "Үндсэн боловсруулалтын төрөл сонгоно уу",
                },
              ]}
            >
              <Select placeholder="Үндсэн боловсруулалтын төрөл сонгоно уу">
                {GERMINATION_MAIN_TYPE_LIST.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="byKg"
              label="Үрийн норм кг : "
              rules={[{ required: true, message: "Үрийн норм оруулна уу" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="byUnit"
              label="Үрийн норм сая/ширхэгээр : "
              rules={[{ required: true, message: "Үрийн норм оруулна уу" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="depth"
              label="Үр суултын гүн /см-ээр : "
              rules={[{ required: true, message: "Үр суултын гүн оруулна уу" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              name="plantAmount"
              label="1м2-т цухуйсан ургамлын тоо ш : "
              rules={[
                {
                  required: true,
                  message: "1м2-т цухуйсан ургамлын тоо оруулна уу",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="devStage"
              label="Хөгжлийн үе шат : "
              rules={[
                { required: true, message: "Хөгжлийн үе шат сонгоно уу" },
              ]}
            >
              <Select placeholder="Хөгжлийн үе шат сонгоно уу">
                {GERMINATION_DEVELOPMENT_STAGE_LIST.map((stage) => (
                  <Select.Option key={stage.id} value={stage.id}>
                    {stage.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="wasteDegree" label="Хогтолтын зэрэг баллаар : ">
              <Input />
            </Form.Item>
            <Form.Item name="quality" label="Чанарын үнэлгээ : ">
              <Select placeholder="Чанарын үнэлгээ сонгоно уу">
                {GERMINATION_QUALITY_LIST.map((quality) => (
                  <Select.Option key={quality.id} value={quality.id}>
                    {quality.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};

export default FarmLandGrow;
