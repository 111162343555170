import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface DetailData {
  loading: boolean;
  resultCode: number;
  result: any;
}
const useWarehouseDetail = (
  detailId?: any
): [DetailData, (id: any) => void, any] => {
  const [id, changeId] = useState(detailId);
  const [content, changeContent] = useState<DetailData>({
    loading: false,
    resultCode: 200,
    result: {},
  });
  useEffect(() => {
    if (id) {
      loadContent(id);
    }
  }, [id]);

  const loadContent = useCallback(
    async (id: any) => {
      changeContent({
        ...content,
        loading: true,
      });
      let response = await NetworkApi.requestGet(`/api/warehouse/detail/${id}`);
      if (response.resultCode === 200) {
        changeContent({
          loading: false,
          resultCode: response.resultCode,
          result: response.result ?? {},
        });
      } else {
        changeContent({
          ...content,
          loading: false,
          resultCode: response.resultCode,
        });
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [content]
  );
  return [content, loadContent, changeId];
};
export default useWarehouseDetail;
