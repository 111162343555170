import { useCallback, useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Layout,
  Space,
  Modal,
  Divider,
  Tooltip,
  Popconfirm,
  Tag,
  message,
  Upload,
  Form,
  Input,
} from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  InboxOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import WarehouseChemicalAdd from "./WareHouseChemicalAdd";
import useChemicalWarehouse from "../../hooks/useChemicalWarehouse";
import usePlantWarehouse from "../../hooks/usePlantWarehouse";
import WarehouseHarvestAdd from "./WarehouseHarvestAdd";
import NetworkApi from "../../network/networkApi";
import UserContext from "../../providers/UserContext";
import useCustomerParcelWarehouse from "../../hooks/useCustomerParcelWarehouse";
import moment from "moment";
import { formatNumberWithCommaAndPoint } from "../../helpers/number.helper";

const WareHouse = () => {
  const [visible, setVisible] = useState(false);
  const { user } = useContext(UserContext);
  const [showHarvest, setShowHarvest] = useState(false);
  const [plant, loadPlant, changePlantFilter] = usePlantWarehouse();
  const [parcel, loadParcel] = useCustomerParcelWarehouse();
  const [chemicalWarehouse, loadChemicalWarehouse] = useChemicalWarehouse();
  const [showBuy, setShowBuy] = useState(false);
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [parcelTransferModal, setParcelTransferModal] = useState<{
    visible: boolean;
    id?: string;
  }>({ visible: false });

  useEffect(() => {
    if (!visible || !showBuy) {
      loadChemicalWarehouse();
      loadParcel();
    }
  }, [visible, showBuy]);

  const houseInfo = [
    {
      title: "№",
      width: 60,
      ellipsis: true,
      render: (a: any, b: any, index: any) => index + 1,
    },
    {
      title: "Бодис, бордооны нэр",
      render: (itm: any) => itm.chemical?.technicalName,
    },
    {
      title: "Зориулалт",
      render: (itm: any) => itm.chemical?.purpose?.name,
    },
    {
      title: "Олон улсын бүртгэлийн дугаар /CAS/",
      render: (itm: any) => itm.chemical?.registrationNumber,
    },
    {
      title: "Огноо",
      dataIndex: "importDate",
    },
    {
      title: "Хаанаас",
      render: (value: any) => (value.seller ? `${value.seller?.name}` : ""),
    },
    {
      title: "Хэмжээ",
      dataIndex: "amount",
      render: (amount: any) =>
        `${formatNumberWithCommaAndPoint(amount ?? 0)} кг`,
    },
    {
      title: "Үлдэгдэл",
      dataIndex: "remaining",
      render: (remaining: any) =>
        `${formatNumberWithCommaAndPoint(remaining ?? 0)} кг`,
    },
  ];

  const columnsParcel = [
    {
      title: "№",
      render: (a: any, b: any, index: any) => index + 1,
    },
    {
      title: "Бодис, бордооны нэр",
      dataIndex: "chemical",
      render: (value: any) => `${value?.technicalName}`,
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      render: (value: string) =>
        `${value ? moment(value).format("YYYY-MM-DD") : ""}`,
    },
    {
      title: "Хэмжээ",
      dataIndex: "parcel",
    },
    {
      title: "Үлдэгдэл",
      dataIndex: "id",
      render: (value: string, row: any) =>
        `${(row.parcel || 0) - (row.totalRemove || 0)}`,
    },
    // {
    //     title: 'Бүртгэсэн',
    //     dataIndex: 'reporter',
    //     key: 'reporter',
    // },
    {
      title: "Устгалд шилжүүлэх",
      dataIndex: "id",
      render: (value: string) => (
        <Row justify="center">
          <Button
            onClick={() =>
              setParcelTransferModal({
                visible: true,
                id: value,
              })
            }
            type={"primary"}
            size={"small"}
            danger
            ghost
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    },
  ];
  const columnsT = [
    {
      title: "№",
      render: (a: any, b: any, index: any) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Таримлын төрөл",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.plant.type.name}` : "-"),
    },
    {
      title: "Таримал",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.plant.name}` : "-"),
    },
    {
      title: "Таримлын сорт",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.name}` : "-"),
    },
    {
      title: "Хаанаас",
      render: (itm: any) => {
        if (itm.type == "harvest") {
          return `НТД-${itm.farmlandPlant?.usage?.farmland?.posNo}`;
        }
        if (itm.type == "import") {
          return itm.country?.name;
        }
        if (itm.type == "natural") {
          return `${itm.aimag?.name}-${itm.sum?.name}`;
        }
      },
    },
    {
      title: "Төрөл",
      render: (itm: any) => {
        if (itm.type == "harvest") {
          return "Таримал";
        }
        if (itm.type == "import") {
          return "Импорт";
        }
        if (itm.type == "natural") {
          return "Байгалийн";
        }
      },
    },
    {
      title: "Хэмжээ",
      render: (itm: any) =>
        `${formatNumberWithCommaAndPoint(itm.amount ?? 0)} ${
          itm.sort?.unit ? itm.sort.unit.name : ""
        }`,
    },
    {
      title: "Үлдэгдэл",
      render: (itm: any) =>
        `${formatNumberWithCommaAndPoint(itm.remaining ?? 0)} ${
          itm.sort?.unit ? itm.sort.unit.name : ""
        }`,
    },
    {
      title: "Огноо",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <>
      <BreadMe title={["Агуулах"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              {user.organizations &&
              user.organizations[0].license?.length > 0 ? (
                <Button onClick={() => setVisible(true)} type={"primary"}>
                  <PlusSquareOutlined />
                  Бодис, бордоо нэмэх
                </Button>
              ) : null}
              {user.organizations?.length > 0 ? (
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={() => setShowBuy(true)}
                  type={"primary"}
                >
                  <PlusSquareOutlined />
                  Бодис, бордоо худалдан авалт
                </Button>
              ) : null}
            </Row>
          </Col>
          <Col span={24}>
            <h4>Бодис, бордооны бүртгэл</h4>
          </Col>
          <Col span={24}>
            <Table
              columns={houseInfo}
              loading={chemicalWarehouse.loading}
              dataSource={chemicalWarehouse.result}
            />
          </Col>
        </Row>
        <Divider plain />
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <h4>Сав баглаа</h4>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsParcel}
              dataSource={parcel.result}
              loading={parcel.loading}
            />
            {parcelTransferModal.visible && (
              <ParcelTransferModal
                visible={parcelTransferModal.visible}
                id={parcelTransferModal.id}
                onHide={() =>
                  setParcelTransferModal({
                    visible: false,
                    id: undefined,
                  })
                }
                onSuccess={() => {
                  loadParcel();
                  setParcelTransferModal({
                    visible: false,
                    id: undefined,
                  });
                }}
              />
            )}
          </Col>
        </Row>
        <Divider plain />
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Button
                onClick={() => setShowHarvest(true)}
                type={"primary"}
                disabled={true}
              >
                <PlusSquareOutlined />
                Бүтээгдэхүүн нэмэх
              </Button>
            </Row>
          </Col>
          <Col span={24}>
            <h4>Таримал бүтээгдэхүүний бүртгэл</h4>
          </Col>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              pagination={{
                current: plant.page,
                pageSize: plant.pageSize,
                total: plant.count,
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize);
                },
              }}
              onChange={(p) => {
                changePlantFilter({
                  ...plant,
                  page: p.current || 1,
                  pageSize: p.pageSize || 10,
                });
              }}
              loading={plant.loading}
              columns={columnsT}
              dataSource={plant.result}
            />
          </Col>
        </Row>
      </Layout.Content>
      <BuyList
        visible={showBuy}
        onHide={() => {
          setShowBuy(false);
        }}
      />
      <WarehouseChemicalAdd
        visible={visible}
        onHide={() => setVisible(false)}
      />
      <WarehouseHarvestAdd
        visible={showHarvest}
        onHide={() => {
          setShowHarvest(false);
          loadPlant();
        }}
      />
    </>
  );
};
interface Props {
  visible: boolean;
  onHide: any;
}
interface ContentData {
  result: any[];
  loading: boolean;
}
const BuyList = ({ visible, onHide }: Props) => {
  const [content, updateContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    if (visible) {
      loadContent();
    }
  }, [visible]);
  const decline = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(
      `/api/chemical/buy/decline/${id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай цуцлагдлаа");
      loadContent();
    } else {
      message.error(
        response.message || "Системд алдаа гарлаа дахин оролдоно уу"
      );
    }
  }, []);
  const confirm = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(
      `/api/chemical/buy/confirm/${id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай баталгаажлаа");
      loadContent();
    } else {
      message.error(
        response.message || "Системд алдаа гарлаа дахин оролдоно уу"
      );
    }
  }, []);
  const columns = [
    {
      title: "Бодис, бордооны төрөл",
      render: (value: any) => value.chemical?.type?.name,
    },
    {
      title: "Бодис, бордооны нэр",
      render: (value: any) => value.chemical?.technicalName,
    },
    {
      title: "Огноо",
      dataIndex: "saleDate",
    },
    {
      title: "Хаанаас",
      render: (value: any) => `${value.seller?.name}`,
    },
    {
      title: "Хэмжээ",
      render: (value: any) => `${value.amount}${value.chemical?.unit?.name}`,
    },
    {
      title: "Баталгаажуулах",
      key: "check",
      render: (value: any) => (
        <Space size={10}>
          {value.status == "pending" ? (
            <>
              <Tooltip title="Баталгаажуулах">
                <Popconfirm
                  placement="topLeft"
                  title={"Та баталгаажуулахдаа итгэлтэй байна уу?"}
                  onConfirm={() => {
                    confirm(value.id);
                  }}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    type={"primary"}
                    size={"small"}
                    icon={<CheckOutlined />}
                  ></Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip title="Устгах">
                <Popconfirm
                  placement="topLeft"
                  title={"Та устгахдаа итгэлтэй байна уу?"}
                  onConfirm={() => {
                    decline(value.id);
                  }}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    type={"primary"}
                    danger
                    size={"small"}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Popconfirm>
              </Tooltip>
            </>
          ) : (
            <Tag color="green">Баталгаажсан</Tag>
          )}
        </Space>
      ),
    },
  ];
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/chemical/buy/list");
    updateContent({
      loading: false,
      result: response.result || [],
    });
  }, []);
  return (
    <Modal
      width={1000}
      visible={visible}
      onCancel={onHide}
      title="Бодис, бордооны худалдан авалт"
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={content.result}
        scroll={{ x: true }}
      />
    </Modal>
  );
};

const uploadFile = (file: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await NetworkApi.requestUploadFile(file.originFileObj);

      if (response.resultCode === 200) {
        resolve(response.result.name);
      } else {
        throw Error(`Файл хуулхад алдаа гарлаа.`);
      }
    } catch (e) {
      reject(e);
    }
  });

const uploadFiles = (files: any[]) =>
  new Promise((resolve, reject) => {
    const promises = files.map((file) => uploadFile(file));
    Promise.all(promises)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });

const ParcelTransferModal = ({
  id,
  visible,
  onHide,
  onSuccess,
}: {
  id?: string;
  visible: boolean;
  onHide: any;
  onSuccess: any;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: any) => {
    setLoading(true);
    const images = await uploadFiles(values.images);
    let response = await NetworkApi.requestPost(
      `/api/parcel/remove/request/${id}`,
      {
        amount: values.amount,
        images,
      }
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай устгалд шилжүүлэгдлээ");
      form.resetFields();
      onSuccess();
    } else {
      message.error(response.message || "Устгалд шилжүүлэх үед алдаа гарлаа");
    }
    setLoading(false);
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onHide();
      }}
      title="Устгалд шилжүүлэх"
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="amount"
          label="Тоо хэмжээ"
          rules={[
            { required: true, message: "Тоо хэмжээ оруулна уу" },
            () => ({
              validator(rule, value) {
                if (value && !/^\d+$/.test(value)) {
                  return Promise.reject("Зөвхөн бүхэл тоо оруулна уу");
                }
                if (value && parseInt(value) <= 0) {
                  return Promise.reject("Тэгээс их тоо оруулна уу");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Тоо хэмжээ оруулна уу" />
        </Form.Item>
        <Form.Item
          name="images"
          valuePropName="fileList"
          getValueFromEvent={(e: any) => {
            return e.fileList;
          }}
          rules={[{ required: true, message: "Зураг оруулна уу" }]}
        >
          <Upload.Dragger
            name="file"
            multiple
            accept="image/*"
            customRequest={(value: any) => {
              setTimeout(() => {
                value.onSuccess({ status: "success" });
              }, 600);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Устгал шилжүүлэх сав баглааны зураг оруулах
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Divider />
        <Button loading={loading} type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default WareHouse;
