import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";

interface ContentData {
  result: any;
  resultCode: number;
  loading: boolean;
}
const useUserDetail = (): [ContentData, (userId: any) => void] => {
  const [id, changeId] = useState<any>(null);
  const [content, changeContent] = useState<ContentData>({
    loading: true,
    resultCode: 200,
    result: {},
  });
  useEffect(() => {
    if (id) {
      loadContent(id);
    }
  }, [id]);
  const loadContent = useCallback(
    async (userId: any) => {
      changeContent({
        ...content,
        loading: true,
      });
      let response = await NetworkApi.requestGet(
        `/api/user/detail/${userId}`,
        {}
      );
      changeContent({
        loading: false,
        resultCode: response.resultCode,
        result: response.result || {},
      });
    },
    [content]
  );

  return [content, loadContent];
};
export default useUserDetail;
