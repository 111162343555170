import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
  Checkbox,
  Table,
  Space,
  Modal,
  Popover,
} from "antd";

import { useCallback, useContext, useEffect, useState } from "react";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import NetworkApi from "../../network/networkApi";
import UserContext from "../../providers/UserContext";
import BreadMe from "../lib/Breadme";
import { formatNumberWithComma } from "../../helpers/number.helper";
import { SUPPORT_TYPE, SUPPORT_TYPE_LIST } from "../../enums/support.enum";
import SupportProductAdd, {
  EbarimtType,
  SupportItemType,
} from "./atoms/SupportProductAdd";
import { isEmpty } from "lodash";
import useSupportFarmlandList from "../../hooks/useSupportFarmlandList";
import SupportLandAdd, { SupportLandType } from "./atoms/SupportLandAdd";
import { ColumnsType } from "antd/es/table";

const Option = Select.Option;

const SupportOthersAdd = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const { user } = useContext(UserContext);
  const [farmLandList, loadFarmlandList] = useSupportFarmlandList();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user.confirm !== "yes") {
      Modal.warning({
        title: "Анхааруулга",
        content:
          "Таны тариаланчийн мэдээлэл баталгаажаагүй байна. Та хариуцсан сумын ХААТ-ын мэргэжилтэнд хандаж баталгаажуулалт хийлгэнэ үү",
        okText: "За",
        onOk: () => {
          history.goBack();
        },
        onCancel: () => {
          history.goBack();
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (form && user.role.cd == "VILLAGE_AGRONOMIST") {
      form.setFieldsValue({
        aimagId: user.aimagId,
        sumId: user.sumId,
      });
    }
  }, [user, form]);

  // Managing support type
  const [selectedSupportType, setSelectedSupportType] = useState(
    SUPPORT_TYPE.FERTILIZER
  );

  useEffect(() => {
    if (selectedSupportType === SUPPORT_TYPE.TECHNIC) {
      showWarningTechnic();
    }
    if (selectedSupportType) {
      loadFarmlandList(selectedSupportType);
    }
  }, [selectedSupportType]);

  // Managing bank account
  const [loadingBank, changeLoadingBank] = useState<boolean>(false);
  const [bankList, changeBankList] = useState<any[]>([]);

  const loadBankList = useCallback(async () => {
    changeLoadingBank(true);
    const response = await NetworkApi.requestGet("/api/bank/list");
    if (response.resultCode === 200) {
      changeBankList(response.result ?? []);
    } else {
      changeBankList([]);
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    changeLoadingBank(false);
  }, []);

  useEffect(() => {
    if (user.confirm === "yes") {
      loadBankList();
    }
  }, [user]);

  const [checkingBank, changeCheckingBank] = useState<boolean>(false);
  const [checkedBank, changeCheckedBank] = useState<boolean>(false);
  const [selectedBank, changeSelectedBank] = useState<any | undefined>();
  const [accountNumber, changeAccountNumber] = useState<string | undefined>();
  const [accountName, changeAccountName] = useState<string | undefined>();

  const showWarningTechnic = () => {
    Modal.warning({
      title: "Анхааруулга",
      content: (
        <div>
          <div>
            Төсвийн санхүүжилт дууссан тул техникийн татаас олгох 2024 оны
            хүсэлт хүлээн авахыг зогсоосон.
          </div>
          <div style={{ textAlign: "center", padding: "3px 0px" }}>
            <span>
              Утас:{" "}
              <a href="tel:51263408" style={{ color: "#1890ff" }}>
                51263408
              </a>
            </span>
          </div>
          <div style={{ textAlign: "center", padding: "3px 0px" }}>ХХААХҮЯ</div>
        </div>
      ),
      okText: "За",
    });
  };

  const onCheckBank = useCallback(async () => {
    if (checkingBank) {
      return;
    }
    changeCheckingBank(true);
    const response = await NetworkApi.requestGet("/api/bank/account", {
      regnum: user.rd,
    });
    if (response.resultCode === 200) {
      changeCheckedBank(true);
      changeSelectedBank({
        code: response.result.bankCode,
        name: response.result.bankName,
        bic: response.result.bankBIC,
      });
      changeAccountNumber(response.result.accountNumber);
      changeAccountName(`${user.lastName} ${user.firstName}`.toUpperCase());
    } else {
      Modal.confirm({
        title: "Анхааруулга",
        content: response.message || "Алдаа гарлаа",
        okText: "Ойлголоо",
        okCancel: false,
      });
      changeCheckedBank(false);
      changeSelectedBank(undefined);
      changeAccountNumber(undefined);
      changeAccountName(undefined);
    }
    changeCheckingBank(false);
  }, [checkedBank, accountNumber, user]);

  const [loadingCamName, changeLoadingCamName] = useState<boolean>(false);
  const [camName, changeCamName] = useState<any | undefined>();
  const [camStatus, changeCamStatus] = useState<boolean>(false);

  useEffect(
    () => changeCamStatus(false),
    [selectedBank, accountNumber, accountName]
  );

  const checkCamName = useCallback(async () => {
    changeLoadingCamName(true);
    changeCamStatus(false);
    let result = await NetworkApi.requestPost("/api/camname/check", {
      bankBic: selectedBank.bic,
      accountNumber: accountNumber,
      accountName: accountName,
    });
    if (
      result.resultCode == 200 &&
      result.result.response !== "CAM NOT REGISTERED"
    ) {
      changeCamName(result.result.camname);
      changeAccountName(result.result.camname);
      form.setFieldsValue({
        accountName: result.result.camname,
      });
      changeCamStatus(true);
    } else {
      Modal.confirm({
        title: "Анхааруулга",
        content: "Буруу байна, дахин мэдээллээ шалгаад оруулна уу!",
        okText: "Ойлголоо",
        okCancel: false,
      });
    }
    changeLoadingCamName(false);
  }, [selectedBank, accountNumber, accountName]);

  // Managing support items

  const [editTechnicItem, changeEditTechnicItem] = useState<{
    visible: boolean;
    edit?: any;
  }>({
    visible: false,
  });

  const setEditTechnicItem = (visible?: boolean, edit?: any) => {
    changeEditTechnicItem({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };

  const [supportItems, changeSupportItems] = useState<SupportItemType[]>([]);

  const removeSupportItem = (index: number) => {
    const items = [...supportItems];
    items.splice(index, 1);
    changeSupportItems(items);
  };

  // Managing support lands
  const [technicTotalLandArea, setTechnicTotalLandArea] = useState<number>(0);
  const [fertilizerTotalLandArea, setFertilizerTotalLandArea] =
    useState<number>(0);

  const [editFertilizerLand, changeEditFertilizerLand] = useState<{
    visible: boolean;
    edit?: any;
  }>({
    visible: false,
  });

  const setEditFertilizerLand = (visible?: boolean, edit?: any) => {
    changeEditFertilizerLand({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };

  const [supportLands, changeSupportLands] = useState<SupportLandType[]>([]);

  useEffect(() => {
    const totalArea = supportLands.reduce(
      (sum, land) => sum + land.usagearea,
      0
    );
    setFertilizerTotalLandArea(totalArea);
  }, [supportLands]);

  const removeSupportLand = (index: number) => {
    const items = [...supportLands];
    items.splice(index, 1);
    changeSupportLands(items);
  };

  const onFinish = useCallback(
    async (values: any) => {
      if (isLoading) return;

      if (selectedSupportType === SUPPORT_TYPE.TECHNIC) {
        showWarningTechnic();
        return;
      }

      if (!camStatus) {
        return;
      }

      setIsLoading(true);
      try {
        const data: any = {
          type: selectedSupportType,
          bankId: selectedBank.code,
          bankName: selectedBank.name,
          accountNumber,
          bankBic: selectedBank.bic,
          camName,
        };

        const preparedSupportItems: SupportItemType[] = [];
        for (const supportItem of supportItems) {
          if (supportItem.type === 1) {
            if (!supportItem.contract.originFileObj) {
              throw Error("Файл хуулахад алдаа гарлаа");
            }
            const response = await NetworkApi.requestUploadFile(
              supportItem.contract.originFileObj
            );
            if (
              response.resultCode !== 200 ||
              !response.result?.name ||
              !response.result?.mimetype
            ) {
              throw Error("Файл хуулахад алдаа гарлаа");
            }
            supportItem.contract = response.result;
          } else {
            if (!supportItem.custom.originFileObj) {
              throw Error("Файл хуулахад алдаа гарлаа");
            }
            const response = await NetworkApi.requestUploadFile(
              supportItem.custom.originFileObj
            );
            if (
              response.resultCode !== 200 ||
              !response.result?.name ||
              !response.result?.mimetype
            ) {
              throw Error("Файл хуулахад алдаа гарлаа");
            }
            supportItem.custom = response.result;
          }
          if (
            selectedSupportType === SUPPORT_TYPE.FERTILIZER &&
            supportItem.landImage
          ) {
            const landImage = [];
            for (const image of supportItem.landImage) {
              if (!image.originFileObj) {
                throw Error("Файл хуулахад алдаа гарлаа");
              }
              const response = await NetworkApi.requestUploadFile(
                image.originFileObj
              );
              if (
                response.resultCode !== 200 ||
                !response.result?.name ||
                !response.result?.mimetype
              ) {
                throw Error("Файл хуулахад алдаа гарлаа");
              }
              if (response.result) {
                landImage.push(response.result);
              }
            }
            if (landImage.length > 0) {
              supportItem.landImage = landImage;
            }
          }
          preparedSupportItems.push(supportItem);
        }

        data.supportItems = preparedSupportItems;

        if (selectedSupportType === SUPPORT_TYPE.TECHNIC) {
          const lands = [];
          if (selectedSupportType === SUPPORT_TYPE.TECHNIC) {
            const farmland = farmLandList.result.find((item) =>
              values.farmlandIds.includes(item.landid)
            );
            if (farmland) {
              lands.push(farmland);
            }
          }
          data.supportLands = lands;
        }

        if (selectedSupportType === SUPPORT_TYPE.FERTILIZER) {
          const lands = [];
          for (const land of supportLands) {
            const file: File = land.file.originFileObj;
            const { result } = await NetworkApi.requestUploadFile(file);
            land.file = result;
            lands.push(land);
          }
          data.supportLands = lands;
        }

        const response = await NetworkApi.requestPost(
          "/api/support/request",
          data
        );
        if (response.resultCode === 200)
          history.push(`/support-others/list/${selectedSupportType}`);
        else
          message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      } catch (e) {
        message.error("Алдаа гарлаа, дахин оролдоно уу!");
      } finally {
        setIsLoading(false);
      }
    },
    [
      isLoading,
      selectedSupportType,
      selectedBank,
      accountNumber,
      camName,
      history,
      supportItems,
      farmLandList,
      supportLands,
      camStatus,
    ]
  );

  const getColumns = (): ColumnsType<SupportItemType> => {
    const columns: ColumnsType<SupportItemType> = [
      {
        title: "№",
        render: (v, r, index: number) => `${index + 1}`,
      },
      {
        title: "Дилер нэр",
        dataIndex: "dealer",
      },
      {
        title: "Дилер регистр",
        dataIndex: "dealerRd",
      },
      {
        title: "Бүтээгдэхүүн",
        dataIndex: "prdProductName",
      },
      {
        title: "Ибаримт код",
        dataIndex: "ebarimt",
        render: (value: EbarimtType[]) => value[0]?.prdBarCode ?? "-",
      },
      {
        title: "Үнийн дүн",
        render: (item: SupportItemType) => {
          if (item.type === 1) {
            return `${formatNumberWithComma(
              item.ebarimt[0].totalAmount ?? 0
            )} ₮`;
          }

          return `${formatNumberWithComma(item.totalPrice ?? 0)} ₮`;
        },
      },
    ];

    if (selectedSupportType === SUPPORT_TYPE.TECHNIC) {
      columns.push({
        title: "Техникийн хувийн хэрэг",
        dataIndex: "contract",
        render: (contract: any) => contract.name,
      });
    }

    if (selectedSupportType === SUPPORT_TYPE.FERTILIZER) {
      columns.push({
        title: "Гаальд мэдүүлсэн файл",
        dataIndex: "custom",
        render: (contract?: any) => contract?.name ?? "-",
      });
      columns.push({
        title: "Гэрээ",
        dataIndex: "contract",
        render: (contract?: any) => contract?.name ?? "-",
      });
      columns.push({
        title: "Бордоо хэрэглэсэн талбайн зураг явцын дүнгийн фото зураг",
        dataIndex: "landImage",
        render: (files: any[]) => {
          return files.map((file: any) => file.name).join(" ,");
        },
      });
    }

    columns.push({
      title: "Үйлдэл",
      render: (v, r, index: number) => (
        <Space size="small">
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeSupportItem(index)}
          />
        </Space>
      ),
    });

    return columns;
  };

  return (
    <>
      <BreadMe title={["", "Дэмжлэгийн сангийн хүсэлт бүртгэх"]} />

      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row justify="center">
          <Col span={22}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
              name="foundation"
              form={form}
            >
              <Form.Item label="Дэмжлэгийн төрөл">
                <Select
                  value={selectedSupportType}
                  onChange={(v) => setSelectedSupportType(v)}
                >
                  {SUPPORT_TYPE_LIST.filter((item) =>
                    [SUPPORT_TYPE.TECHNIC, SUPPORT_TYPE.FERTILIZER].includes(
                      item.id
                    )
                  ).map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Хүсэлт гаргагчийн регистр" name="register">
                <Input disabled defaultValue={user.rd} />
              </Form.Item>
              <Form.Item label="Хүсэлт гаргагчийн овог" name="lastname">
                <Input disabled defaultValue={user.lastName} />
              </Form.Item>
              <Form.Item label="Хүсэлт гаргагчийн нэр" name="firstName">
                <Input disabled defaultValue={user.firstName} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <Space>
                      <>Баталгаажсан тариаланч эсэх</>
                      <Popover
                        content={
                          <div style={{ maxWidth: 500 }}>
                            <p style={{ textAlign: "justify" }}>
                              Та харьяа дүүрэг/сумын хөдөө аж ахуйн тасгийн
                              мэргэжилтэний баталгаажилт хийлгэнэ үү.
                            </p>
                            <p
                              style={{ paddingLeft: 16, textAlign: "justify" }}
                            >
                              • ХААТ-н мэргэжилтэн нь Тариаланчид цэс рүү орж
                              тариаланчийг регистрийн дугаараар хайж олоод
                              Үйлдэл товчийг дарах бөгөөд Баталгаажсан эсэх
                              сонголт дээр очин баталгаажуулж хадгална
                            </p>
                          </div>
                        }
                        title="Анхааруулга"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </Space>
                  </>
                }
                name="confirm"
                style={{ position: "relative" }}
              >
                <Checkbox
                  style={{
                    scale: "150%",
                    top: 0,
                    left: 5,
                    position: "absolute",
                  }}
                  defaultChecked={user.confirm === "yes"}
                  checked={user?.confirm === "yes"}
                />
              </Form.Item>
              {selectedSupportType === SUPPORT_TYPE.TECHNIC && (
                <Form.Item
                  label={
                    <>
                      <Space>
                        <>Талбайн дугаар</>
                        <Popover
                          content={
                            <div style={{ maxWidth: 800 }}>
                              <p style={{ textAlign: "justify" }}>
                                Үйл ажиллагаа явуулж буй талбай нь ГЗБЗЗГ-ийн
                                мэдээллийн системд бүртгэлтэй, мөн хүсэлт
                                гаргагчийн нэр дээрх эзэмшлийн талбай байх
                                шаардлагатай тул хэрвээ жагсаалтанд гарахгүй
                                байгаа бол та харьяа дүүрэг/сумын хөдөө аж ахуйн
                                тасгийн мэргэжилтэнд хандан тариалангийн талбайн
                                геодезийн баталгаажилт хийлгэнэ үү.
                              </p>
                              <p
                                style={{
                                  paddingLeft: 16,
                                  textAlign: "justify",
                                  whiteSpace: "pre-line",
                                  tabSize: 2,
                                }}
                              >
                                {`• ХААТ-н мэргэжилтэн нь "Тариалангийн талбайн жагсаалт" цэс рүү орж харах бөгөөд Төлөв
                                баганад байрлах мэдээлэл "Баталгаасан" төлөвтэй байгаа эсэхийг шалгах хэрэгтэй. Хэрвээ
                                "Систем" төлөвтэй бол зааврын дагуу баталгаажуулна уу.
                                  Заавар: "Тариалангийн талбайн жагсаалт" цэс рүү орж "Дэлгэрэнгүй" хэсэгт байрлах засах товчийг
                                    даран газар эзэмшигчийн регистрийн дугаарыг оруулан хадгалах товч дарсанаар жагсаалтанд
                                    төлөв "Баталгаажсан" болно.
                                • Тухайн газар нь зөвхөн хүсэлт гаргагчийн нэр дээрх эзэмшлийн талбай байна.`}
                              </p>
                            </div>
                          }
                          title="Анхааруулга"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </Space>
                    </>
                  }
                  name="farmlandIds"
                  rules={[
                    { required: true, message: "Талбайн дугаар оруулна уу" },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "100%", minWidth: 150 }}
                    showSearch
                    placeholder="Талбайн дугаар"
                    onChange={(v, selectedLandsOption) => {
                      const landIds = selectedLandsOption.map(
                        (item: any) => item.value
                      );
                      const lands = farmLandList.result.filter((land) =>
                        landIds.includes(land.landid)
                      );
                      const totalArea = lands.reduce(
                        (sum, land) => sum + land.usagearea,
                        0
                      );
                      setTechnicTotalLandArea(totalArea);
                    }}
                  >
                    {farmLandList.result.map((f: any) => {
                      return (
                        <Option key={f.landid} value={f.landid}>
                          НТД-{f.posno}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              {selectedSupportType === SUPPORT_TYPE.FERTILIZER && (
                <Row
                  style={{ marginTop: 16, marginBottom: 16 }}
                  gutter={[16, 16]}
                >
                  {editFertilizerLand.visible && (
                    <SupportLandAdd
                      visible={editFertilizerLand.visible}
                      selectedSupportType={selectedSupportType}
                      supportLandIds={supportLands.map((item) => item.landid)}
                      // edit={edit.edit}
                      onHide={() => setEditFertilizerLand(false)}
                      onSuccess={(newLand: SupportLandType) => {
                        setEditFertilizerLand(false);
                        changeSupportLands([...supportLands, newLand]);
                      }}
                    />
                  )}
                  <Col span={24}>
                    <Row justify="start">
                      <Button
                        type="primary"
                        onClick={() => {
                          if (selectedSupportType === SUPPORT_TYPE.FERTILIZER) {
                            setEditFertilizerLand(true);
                          }
                        }}
                      >
                        Тариалангийн газар нэмэх
                      </Button>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      scroll={{ x: true }}
                      columns={[
                        {
                          title: "№",
                          render: (v, r, indx: number) => `${indx + 1}`,
                        },
                        {
                          title: "НТДугаар",
                          dataIndex: "posno",
                        },
                        {
                          title: "Хэмжээ",
                          dataIndex: "area",
                          render: (value: any) => `${value} га`,
                        },
                        {
                          title:
                            "Газрын агрохими, агрофизикийн лабораторийн шинжилгээний дүн (2019-2023)",
                          dataIndex: "file",
                          render: (value: any) => `${value.name}`,
                        },
                        {
                          title: "Үйлдэл",
                          render: (v, r, index: number) => (
                            <Space size="small">
                              <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => removeSupportLand(index)}
                              />
                            </Space>
                          ),
                        },
                      ]}
                      dataSource={supportLands}
                    />
                  </Col>
                </Row>
              )}
              {[SUPPORT_TYPE.TECHNIC].includes(selectedSupportType) && (
                <Form.Item label="ГЗБГЗЗГ-аар баталгаажсан талбайн нийт хэмжээ">
                  <Input
                    disabled
                    value={`${technicTotalLandArea ?? "0"}  га`}
                  />
                </Form.Item>
              )}
              {[SUPPORT_TYPE.FERTILIZER].includes(selectedSupportType) && (
                <Form.Item
                  label={
                    <>
                      <Space>
                        <>Талбайн нийт хэмжээ</>
                        <Popover
                          content={
                            <div style={{ maxWidth: 800 }}>
                              <p style={{ textAlign: "justify" }}>
                                Үйл ажиллагаа явуулж буй талбай нь ГЗБЗЗГ-ийн
                                мэдээллийн системд бүртгэлтэй, мөн хүсэлт
                                гаргагчийн нэр дээрх эзэмшлийн талбай байх
                                шаардлагатай тул хэрвээ жагсаалтанд гарахгүй
                                байгаа бол та харьяа дүүрэг/сумын хөдөө аж ахуйн
                                тасгийн мэргэжилтэнд хандан тариалангийн талбайн
                                геодезийн баталгаажилт хийлгэнэ үү.
                              </p>
                              <p
                                style={{
                                  paddingLeft: 16,
                                  textAlign: "justify",
                                  whiteSpace: "pre-line",
                                  tabSize: 2,
                                }}
                              >
                                {`• ХААТ-н мэргэжилтэн нь "Тариалангийн талбайн жагсаалт" цэс рүү орж харах бөгөөд Төлөв
                            баганад байрлах мэдээлэл "Баталгаасан" төлөвтэй байгаа эсэхийг шалгах хэрэгтэй. Хэрвээ
                            "Систем" төлөвтэй бол зааврын дагуу баталгаажуулна уу.
                              Заавар: "Тариалангийн талбайн жагсаалт" цэс рүү орж "Дэлгэрэнгүй" хэсэгт байрлах засах товчийг
                                даран газар эзэмшигчийн регистрийн дугаарыг оруулан хадгалах товч дарсанаар жагсаалтанд
                                төлөв "Баталгаажсан" болно.
                            • Тухайн газар нь зөвхөн хүсэлт гаргагчийн нэр дээрх эзэмшлийн талбай байна.`}
                              </p>
                            </div>
                          }
                          title="Анхааруулга"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </Space>
                    </>
                  }
                >
                  <Input
                    disabled
                    value={`${fertilizerTotalLandArea ?? "0"}  га`}
                  />
                </Form.Item>
              )}
              {[SUPPORT_TYPE.TECHNIC, SUPPORT_TYPE.FERTILIZER].includes(
                selectedSupportType
              ) && (
                <>
                  <Form.Item label="Тариалалт бүртгэсэн эсэх">
                    <Checkbox
                      style={{
                        scale: "150%",
                        top: 0,
                        left: 5,
                        position: "absolute",
                      }}
                      checked
                    />
                  </Form.Item>
                  {editTechnicItem.visible && (
                    <SupportProductAdd
                      visible={editTechnicItem.visible}
                      selectedSupportType={selectedSupportType}
                      onHide={() => setEditTechnicItem(false)}
                      onSuccess={(newItem: SupportItemType) => {
                        setEditTechnicItem(false);
                        changeSupportItems([...supportItems, newItem]);
                      }}
                    />
                  )}
                  <Row
                    style={{ marginTop: 16, marginBottom: 16 }}
                    gutter={[16, 16]}
                  >
                    <Col span={24}>
                      <Row justify="start">
                        <Button
                          type="primary"
                          onClick={() => setEditTechnicItem(true)}
                        >
                          Бүтээгдэхүүн нэмэх
                        </Button>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Table
                        scroll={{ x: true }}
                        columns={getColumns()}
                        dataSource={supportItems}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Form.Item
                label={
                  <>
                    <Space>
                      <>Банк сонгох</>
                      <Popover
                        content={
                          <div style={{ maxWidth: 500 }}>
                            <p style={{ textAlign: "justify" }}>
                              Дэмжлэгийн хүсэлт илгээж буй хэрэглэгчийн мэдээлэл
                              дэмжлэг хүлээн авагчийн дансны мэлээлэл таарч
                              байхыг анхаарна уу!
                            </p>
                          </div>
                        }
                        title="Анхааруулга"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </Space>
                  </>
                }
                name="bankId"
                rules={[
                  {
                    required: true,
                    message: "Банк сонгоно уу",
                  },
                ]}
              >
                <Select
                  loading={loadingBank}
                  placeholder="Банк сонгоно уу"
                  onChange={(v) => {
                    if (v) {
                      const bank = bankList.find(
                        (item) => `${item.code}` === `${v}`
                      );
                      changeSelectedBank(bank);
                    } else {
                      changeSelectedBank(undefined);
                    }
                  }}
                >
                  {bankList.map((t: any) => (
                    <Select.Option key={`bank-${t.code}`} value={t.code}>
                      {t.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Дансны дугаар"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message: "Дансны дугаар оруулна уу",
                  },
                  {
                    validator: (_rule, value) => {
                      if (isEmpty(value)) Promise.resolve();
                      if (!/^[0-9]*$/.test(value))
                        return Promise.reject(
                          new Error("Зөвхөн тоо оруулна уу")
                        );
                      if (!selectedBank) return Promise.resolve();
                      if (9 > value.length)
                        return Promise.reject(
                          new Error(`Дансны дугаарын урт багадаа 9 байх ёстой`)
                        );
                      if (12 < value.length)
                        return Promise.reject(
                          new Error(`Дансны дугаарын урт ихдээ 12 байх ёстой`)
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Дансны дугаар оруулна уу"
                  onChange={(e) => {
                    changeAccountNumber(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Данс эзэмшигчийн овог, нэр"
                name="accountName"
                rules={[
                  {
                    required: true,
                    message: "Данс эзэмшигчийн овог, нэр оруулна уу",
                  },
                ]}
              >
                <Input
                  placeholder="Данс эзэмшигчийн овог, нэр оруулна уу"
                  onChange={(e) => {
                    changeAccountName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="end">
                  <Space>
                    <Button
                      loading={loadingCamName}
                      type="primary"
                      style={{ borderRadius: 10 }}
                      disabled={!accountNumber || !accountName || !selectedBank}
                      onClick={() => checkCamName()}
                    >
                      Шалгах
                    </Button>
                  </Space>
                </Row>
              </Form.Item>
              {selectedBank && accountNumber && accountName && camStatus && (
                <Form.Item label="Хүлээн авах данс">
                  <div>
                    <>
                      {`${selectedBank.name} `}
                      <span style={{ color: "cornflowerblue" }}>
                        {`${accountNumber} ${accountName}`}
                      </span>
                    </>
                  </div>
                </Form.Item>
              )}
              {selectedSupportType === SUPPORT_TYPE.TECHNIC &&
                user &&
                selectedBank &&
                accountNumber &&
                accountName &&
                camStatus && (
                  <Form.Item label="Хүсэлт">
                    <div style={{ textAlign: "justify" }}>
                      Иргэн/ Хуулийн этгээд{" "}
                      <span style={{ color: "cornflowerblue" }}>{`${
                        user.lastName ? `${user.lastName.substring(0, 1)}.` : ""
                      }${user.firstName}`}</span>{" "}
                      нь "Хүнсний хангамж, аюулгүй байдлыг хангах талаар авах
                      зарим арга хэмжээний тухай "Монгол Улсын Их Хурлын 2022
                      оны 36 дугаар тогтоолыг хэрэгжүүлэх төлөвлөгөөний 3.3.2
                      дахь заалт "Төмс, хүнсний ногооны үйлдвэрлэлийн
                      механикжуулалтыг сайжруулах, техник, технологийн шинэчлэл
                      хийх зорилгоор тухайн жилд худалдан авсан техникт 50
                      хувийн хөнгөлөлт үзүүлэх" арга хэмжээг хэрэгжүүлэх "Үнийн
                      дүнгийн 50 хувийг нь буцаан төлөх төмс, хүнсний ногооны
                      техникийг тодорхойлох" баримт бичигт заасны дагуу буцаан
                      олголтыг {`${selectedBank.name}-ны `}
                      <span style={{ color: "cornflowerblue" }}>
                        {`${accountNumber} ${accountName}`}
                      </span>{" "}
                      дансанд авах хүсэлтэй байна
                    </div>
                  </Form.Item>
                )}
              {selectedSupportType === SUPPORT_TYPE.FERTILIZER &&
                user &&
                selectedBank &&
                accountNumber &&
                accountName &&
                camStatus && (
                  <Form.Item label="Хүсэлт">
                    <div style={{ textAlign: "justify" }}>
                      Иргэн/ Хуулийн этгээд{" "}
                      <span style={{ color: "cornflowerblue" }}>{`${
                        user.lastName ? `${user.lastName.substring(0, 1)}.` : ""
                      }${user.firstName}`}</span>{" "}
                      нь "Хүнсний хангамж, аюулгүй байдлыг хангах талаар авах
                      зарим арга хэмжээний тухай "Монгол Улсын Их Хурлын 2022
                      оны 36 дугаар тогтоолыг хэрэгжүүлэх төлөвлөгөөний 3.6.2-т
                      заасан "Бордоо хөнгөлөлттэй нөхцөлөөр олгох" арга хэмжээг
                      хэрэгжүүлэх зорилгоор Монгол Улсын засгийн газрын тогтоолд
                      нэмэлт өөрчлөлт оруулах 2023 оны 147 дугаар тогтоолыг
                      үндэслэн, Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яамнаас
                      хаврын тариалалтад шаардлагатай бордоо худалдан авсан
                      иргэн, хуулийн этгээдэд бордооны үнийн дүнгийн 30 хүртэл
                      хувьд татаас олгох баримт бичигт заасны дагуу буцаан
                      олголтыг {`${selectedBank.name}-ны `}
                      <span style={{ color: "cornflowerblue" }}>
                        {`${accountNumber} ${accountName}`}
                      </span>{" "}
                      дансанд авах хүсэлтэй байна
                    </div>
                  </Form.Item>
                )}
              <Form.Item>
                <Row justify="end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={!camStatus}
                  >
                    Хүсэлт илгээх
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default SupportOthersAdd;
