import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
}
const useUserSortWarehouse = (): [ContentData, () => void, any] => {
  const [filter, changeFilter] = useState<{
    "plantId[]": any;
  }>({ "plantId[]": [] });
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    if (filter["plantId[]"].length > 0) {
      loadContent();
    }
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(
      "/api/warehouse/user/sort/list",
      filter
    );
    changeContent({
      loading: false,
      result: response.result?.rows || [],
    });
  }, [content, filter]);

  return [content, loadContent, changeFilter];
};
export default useUserSortWarehouse;
