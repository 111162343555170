import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  categories: any[];
  sub_categories: any[];
  products: any[];
  loading: boolean;
}
const useProducts = (page?: number): [ContentData, () => void, any] => {
  const [filter, changeFilter] = useState<any>(null);
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    categories: [],
    sub_categories: [],
    products: [],
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet("/api/support/products", filter);
    if (response.resultCode == 200)
      changeContent({
        loading: false,
        categories: response.result.categories || [],
        sub_categories: response.result.sub_categories || [],
        products: response.result.products || [],
      });
  }, [content, filter]);

  return [content, loadContent, changeFilter];
};
export default useProducts;
