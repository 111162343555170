import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import NetworkApi from "../../../network/networkApi";
import moment from "moment";
import UserContext from "../../../providers/UserContext";
import { UploadOutlined } from "@ant-design/icons";
import { formatNumberWithComma } from "../../../helpers/number.helper";
import { mockUploadAction } from "../../../helpers/upload.helper";
import { SUPPORT_TYPE } from "../../../enums/support.enum";
import { RangePickerProps } from "antd/lib/date-picker";
import {
  DealerProductType,
  DealerType,
  EbarimtResultType,
  EbarimtType,
  SupportItemType,
} from "./SupportProductAdd";

interface Props {
  id?: any;
  selectedSupportType: string;
  onSuccess: (item: SupportItemType) => void;
}

export default function SupportProductForm1({
  id,
  selectedSupportType,
  onSuccess,
}: Props) {
  const { user } = useContext(UserContext);

  const [dealerC, changeDealerC] = useState<{
    loading: boolean;
    result: DealerType[];
  }>({
    loading: true,
    result: [],
  });
  const [selectedDealer, changeSelectedDealer] = useState<
    DealerType | undefined
  >();

  const loadDealers = useCallback(async () => {
    changeDealerC({
      ...dealerC,
      loading: true,
    });
    const response = await NetworkApi.requestPost("/api/support/dealers", {
      type: selectedSupportType,
    });

    if (response.resultCode !== 200) {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      changeDealerC({
        ...dealerC,
        loading: false,
      });
    }
    if (response.resultCode === 200) {
      if (
        response.result.organisations &&
        Array.isArray(response.result.organisations)
      ) {
        changeDealerC({
          ...dealerC,
          loading: false,
          result: response.result.organisations,
        });
      } else {
        message.error(
          response.message ||
            "Худалдан авсан байгууллага олдсонгүй, дахин оролдоно уу!"
        );
        changeDealerC({
          ...dealerC,
          loading: false,
        });
      }
    }
  }, [selectedSupportType]);

  useEffect(() => {
    loadDealers();
  }, [selectedSupportType]);

  const [selectedProduct, changeSelectedProduct] = useState<
    DealerProductType | undefined
  >();
  const [selectedMonth, changeSelectedMonth] = useState<moment.Moment | null>(
    null
  );

  const [ebarimtC, changeEbarimtC] = useState<{
    loading: boolean;
    result?: EbarimtResultType;
  }>({
    loading: false,
  });

  const loadEbarimt = useCallback(async () => {
    changeDealerC({
      ...dealerC,
      loading: true,
    });
    const response = await NetworkApi.requestPost("/api/support/ebarimt", {
      type: selectedSupportType,
      rd: user.rd,
      year: moment().format("YYYY"),
      dealer: selectedDealer?.name,
      dealerRd: selectedDealer?.register,
      productCode: selectedProduct?.e_barimt_code,
      month: selectedMonth?.format("MM"),
      id,
    });

    console.log("ebarimt response result: ", response.result);

    if (response.resultCode !== 200) {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      changeEbarimtC({
        ...ebarimtC,
        loading: false,
      });
    } else {
      changeEbarimtC({
        ...ebarimtC,
        loading: false,
        result: response.result,
      });
    }
  }, [selectedProduct, selectedMonth]);

  useEffect(() => {
    if (selectedProduct && selectedMonth) {
      loadEbarimt();
    }
  }, [selectedProduct, selectedMonth]);

  const [selectedEbarimt, changeSelectedEbarimt] = useState<
    EbarimtType | undefined
  >();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return (
      current &&
      (current < moment().startOf("year") || current > moment().endOf("year"))
    );
  };

  const [marketPrice, setMarketPrice] = useState<number | undefined>();

  useEffect(() => {
    if (selectedProduct && selectedEbarimt) {
      const estimatedPrice =
        parseFloat(selectedProduct.price) *
        parseFloat(`${selectedEbarimt.totalCount}`);
      setMarketPrice(Math.min(estimatedPrice, selectedEbarimt.totalAmount));
    }
  }, [selectedProduct, selectedEbarimt]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  const [cabinNo, setCabinNo] = useState<string | undefined>();
  const [cabinNoC, setCabinNoC] = useState<{
    loading: boolean;
    status: boolean;
  }>({
    loading: false,
    status: false,
  });

  const checkCabinNo = useCallback(async () => {
    setCabinNoC({
      ...cabinNoC,
      loading: true,
    });
    const response = await NetworkApi.requestGet("/api/support/technic/check", {
      cabinNo,
    });

    console.log("cabinno response result: ", response.result);

    if (response.resultCode !== 200) {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      setCabinNoC({
        ...cabinNoC,
        loading: false,
      });
    } else {
      setCabinNoC({
        ...cabinNoC,
        loading: false,
        status: response.result?.length ?? false,
      });
    }
  }, [cabinNo]);

  return (
    <Form
      name="product-add"
      layout="vertical"
      onFinish={(values) => {
        if (!selectedDealer) return;
        if (!selectedProduct) return;
        if (!ebarimtC.result) return;
        if (!selectedEbarimt) return;
        if (!marketPrice) return;

        const data: SupportItemType = {
          type: 1,
          dealer: ebarimtC.result.dealer,
          dealerRd: ebarimtC.result.dealerRd,
          dealerId: selectedDealer?.id,
          productId: selectedProduct?.id,
          productCode: ebarimtC.result.productCode,
          prdProductName: ebarimtC.result.prdProductName,
          ebTotalAmount: ebarimtC.result.ebTotalAmount,
          ebTotalCount: ebarimtC.result.ebTotalCount,
          name: selectedProduct.name,
          certRequire: selectedProduct.require_certificate,
          contract: values.contract[0],
          landImage: values.landImage,
          ebarimt: [selectedEbarimt],
          price: +selectedProduct.price,
          totalPrice: marketPrice,
          totalCount: 0,
        };

        if (selectedSupportType === SUPPORT_TYPE.TECHNIC) {
          if (selectedProduct.require_certificate && !cabinNoC.status) {
            message.error("Арлын дугаараа шалгана уу");
            return;
          }
          data.xyrStatus = cabinNoC.status;
          data.cabinNo = cabinNo;
        }

        onSuccess(data);
      }}
    >
      <Form.Item
        label="Худалдан авсан байгууллагын нэр"
        name="dealer"
        rules={[
          {
            required: true,
            message: "Худалдан авсан байгууллага сонгоно уу",
          },
        ]}
      >
        <Select
          loading={dealerC.loading}
          placeholder="Сонгоно уу"
          onChange={(dealerId: number) => {
            if (dealerId > 0) {
              const dealer = dealerC.result.find(
                (item) => +item.id === +dealerId
              );
              changeSelectedDealer(dealer);
            } else {
              changeSelectedDealer(undefined);
            }
            changeSelectedProduct(undefined);
            changeSelectedEbarimt(undefined);
            setMarketPrice(undefined);
          }}
        >
          {dealerC.result.map((item) => (
            <Select.Option value={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Бүтээгдэхүүн"
        name="product"
        rules={[
          {
            required: true,
            message: "Бүтээгдэхүүн сонгоно уу",
          },
        ]}
      >
        <Select
          disabled={!selectedDealer}
          placeholder="Сонгоно уу"
          onChange={(prodId: number) => {
            console.log("onchange product id: ", prodId);
            if (prodId > 0) {
              const product = selectedDealer?.products.find(
                (item) => +item.id === +prodId
              );
              changeSelectedProduct(product);
            } else {
              changeSelectedProduct(undefined);
            }
            changeSelectedEbarimt(undefined);
            setMarketPrice(undefined);
          }}
        >
          {(selectedDealer?.products ?? []).map((item: any) => (
            <Select.Option value={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      {selectedProduct && (
        <Row>
          <Col span={12}>
            <Form.Item label="Ибаримт код">
              {selectedProduct.e_barimt_code}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Нэгж үнэ">
              {`${formatNumberWithComma(parseFloat(selectedProduct.price))} ₮`}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        label="Ибаримт сар"
        name="month"
        rules={[
          {
            required: true,
            message: "Ибаримт сар сонгоно уу",
          },
        ]}
      >
        <DatePicker.MonthPicker
          disabled={!selectedProduct}
          value={selectedMonth}
          disabledDate={disabledDate}
          onChange={(value) => changeSelectedMonth(value)}
        />
      </Form.Item>
      <Form.Item
        label="Ибаримт"
        name="ebarimt"
        rules={[
          {
            required: true,
            message: "Ибаримт сонгоно уу",
          },
        ]}
      >
        <Select
          disabled={!selectedProduct}
          placeholder="Сонгоно уу"
          onChange={(indx: number) => {
            if (indx > -1) {
              changeSelectedEbarimt(ebarimtC.result?.ebarimt[Number(indx)]);
            } else {
              changeSelectedEbarimt(undefined);
              setMarketPrice(undefined);
            }
          }}
        >
          {(ebarimtC?.result?.ebarimt ?? []).map((item: any, index: number) => (
            <Select.Option value={index}>{`${item.prdProductName} (${
              item.totalCount
            } ${item.prdUnitCode ?? ""})`}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      {selectedEbarimt && (
        <Row>
          <Col span={12}>
            <Form.Item label="Ибаримт нийт үнэ">{`${formatNumberWithComma(
              selectedEbarimt.totalAmount ?? "0"
            )} ₮`}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ибаримт хэмжээ">{`${formatNumberWithComma(
              selectedEbarimt.totalCount ?? "0"
            )} ${
              selectedEbarimt.prdUnitCode === "тн"
                ? "кг"
                : selectedEbarimt.prdUnitCode
            } `}</Form.Item>
          </Col>
        </Row>
      )}

      {marketPrice && (
        <Row>
          <Col span={24}>
            <Form.Item label="Жишиг үнэ">{`${formatNumberWithComma(
              marketPrice ?? "0"
            )} ₮`}</Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item
        name="contract"
        label={
          selectedSupportType === SUPPORT_TYPE.TECHNIC
            ? "Техникийн хувийн хэрэг"
            : "Гэрээ"
        }
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: "Файл хавсаргана уу",
          },
        ]}
      >
        <Upload
          accept=".pdf,image/*"
          name="upload"
          customRequest={mockUploadAction}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Файл сонгох</Button>
        </Upload>
      </Form.Item>
      {selectedSupportType === SUPPORT_TYPE.FERTILIZER && (
        <Form.Item
          name="landImage"
          label="Бордоо хэрэглэсэн талбайн зураг, явцын дүнгийн зураг"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Файл хавсаргана уу",
            },
          ]}
        >
          <Upload
            accept=".pdf,image/*"
            name="upload"
            customRequest={mockUploadAction}
            maxCount={5}
          >
            <Button icon={<UploadOutlined />}>Файл сонгох</Button>
          </Upload>
        </Form.Item>
      )}
      {selectedSupportType === SUPPORT_TYPE.TECHNIC &&
        selectedProduct?.require_certificate && (
          <>
            <Form.Item
              label="Арлын дугаар"
              name="cabinNo"
              rules={[
                {
                  required: true,
                  message: "Арлын дугаар оруулна уу",
                },
              ]}
            >
              <Input
                placeholder="Арлын дугаар оруулна уу"
                onChange={(e) => {
                  setCabinNo(e.target.value);
                  setCabinNoC({
                    loading: false,
                    status: false,
                  });
                }}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Button
                  loading={cabinNoC.loading}
                  type="primary"
                  style={{ borderRadius: 10 }}
                  onClick={() => checkCabinNo()}
                >
                  Шалгах
                </Button>
              </Row>
            </Form.Item>
          </>
        )}
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Нэмэх
        </Button>
      </Form.Item>
    </Form>
  );
}
