import { useCallback, useState } from "react";
import { message } from "antd";

import NetworkApi from "../network/networkApi";

import { PLAN_STATUS } from "../enums/plan.enum";

export const usePlanApprove = (): [
  boolean,
  (id: string, fn: () => void) => void
] => {
  const [loading, changeLoading] = useState(false);

  const approvePlan = useCallback(async (id, fn: () => void) => {
    changeLoading(true);
    let response = await NetworkApi.requestPost("/api/plan/status/update", {
      id,
      status: PLAN_STATUS.APPROVED,
    });

    changeLoading(false);

    if (response.resultCode === 200) {
      message.success("Амжилттай баталгаажлаа");
      if (fn) {
        fn();
      }
    } else {
      message.error(response.message || "Баталгаажуулахад алдаа гарлаа.");
    }
  }, []);

  return [loading, approvePlan];
};
