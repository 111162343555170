import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
}
const useIncentiveAvailableItems = (): [ContentData, () => void, any] => {
  const [filter, changeFilter] = useState<any>(null);
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    if (filter?.year || filter?.month) {
      loadContent();
    }
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(
      "/api/incentive/available/items",
      filter
    );
    if (response.resultCode == 200)
      changeContent({
        loading: false,
        result: response.result.rows || [],
      });
    else {
      changeContent({
        loading: false,
        result: [],
      });
      Modal.confirm({
        title: "Анхааруулга",
        content: response.message || "Алдаа гарлаа",
        okText: "Ойлголоо",
        okCancel: false,
      });
    }
  }, [content, filter]);

  return [content, loadContent, changeFilter];
};
export default useIncentiveAvailableItems;
