import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
}
const useIncentivePlantSort = (): [ContentData, () => void, any] => {
  const [filter, changeFilter] = useState<any>();
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(
      "/api/incentive/sort/list",
      filter
    );
    if (response.resultCode == 200)
      changeContent({
        loading: false,
        result: response.result.rows || [],
      });
  }, [content, filter]);

  return [content, loadContent, changeFilter];
};
export default useIncentivePlantSort;
