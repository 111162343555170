import { useCallback, useState } from "react";
import NetworkApi from "../network/networkApi";

type SupportOthersDetailContent = {
  loading: boolean;
  result?: any;
};

export default function useSupportOthersDetail(): [
  SupportOthersDetailContent,
  (id: number) => void
] {
  const [content, changeContent] = useState<SupportOthersDetailContent>({
    loading: false,
    result: undefined,
  });
  const loadContent = useCallback(
    async (id: number) => {
      changeContent({
        loading: true,
        result: undefined,
      });
      let response = await NetworkApi.requestGet(`/api/support/detail/${id}`);
      if (+response.resultCode === 200)
        changeContent({
          loading: false,
          result: response.result,
        });
      else
        changeContent({
          ...content,
          loading: false,
        });
    },
    [content]
  );
  return [content, loadContent];
}
