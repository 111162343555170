import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any;
  loading: boolean;
  usageId: any;
  resultCode: number;
}
const useCertificateDetail = (): [ContentData, () => void, any] => {
  const [id, changeId] = useState(null);
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: {},
    usageId: null,
    resultCode: 200,
  });
  useEffect(() => {
    if (id) loadContent();
  }, [id]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(`/api/certificate/detail/${id}`);
    changeContent({
      loading: false,
      result: response.result || {},
      usageId: response.result
        ? response.result.sizes[0]?.warehouse?.farmlandPlant?.usageId
        : null,
      resultCode: response.resultCode,
    });
  }, [content, id]);

  return [content, loadContent, changeId];
};
export default useCertificateDetail;
